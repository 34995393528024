<script lang="ts" setup>
import { VueFinalModal } from "vue-final-modal";

import {
DocumentSubmissionType,
DocumentSubmissionTypeLabels,
} from "~/types/enums/DocumentSubmissionType.enum";

import { Subscription } from "rxjs";

const props = defineProps({
  classroomId: {
    type: String,
    required: true,
  },
  assignmentId: {
    type: String,
    required: true,
  },
});

defineEmits(["close"]);

const classroomDataStore = useClassroomDataStore(
  useCurrentUID()!,
  props.classroomId
);

const { students } = storeToRefs(classroomDataStore);

const assignmentDataStore = useAssignmentDataStore(props.assignmentId);

const {
  assignment,
  ungradedDocuments,
  allowKWOSubmission,
  allowRoughDraftSubmission,
} = storeToRefs(assignmentDataStore);

const gradedDocuments = ref<SubmittedDocuments>([]);

const isLoading = ref(true);

const tabs = ref<Tabs>([]);
const subscription = ref<Subscription | undefined>();
onMounted(() => {
  subscription.value = assignmentDataStore
    .streamGradedDocuments()
    .subscribe((docs: SubmittedDocuments) => {
      isLoading.value = false;
      gradedDocuments.value = docs;
    });

  if (allowKWOSubmission.value) {
    tabs.value.push({
      key: DocumentSubmissionType.keyWordOutline,
      label:
        DocumentSubmissionTypeLabels[DocumentSubmissionType.keyWordOutline]
          .shortLabel,
    });
  }

  if (allowRoughDraftSubmission.value) {
    tabs.value.push({
      key: DocumentSubmissionType.roughDraft,
      label:
        DocumentSubmissionTypeLabels[DocumentSubmissionType.roughDraft]
          .shortLabel,
    });
  }

  tabs.value.push({
    key: DocumentSubmissionType.finalDraft,
    label:
      DocumentSubmissionTypeLabels[DocumentSubmissionType.finalDraft]
        .shortLabel,
  });
});

onUnmounted(() => {
  subscription.value?.unsubscribe();
});

const selectedTabKey = ref<DocumentSubmissionType>(
  DocumentSubmissionType.finalDraft
);

const ungradedDocumentsByType = computed(() => {
  return (documentSubmissionType: DocumentSubmissionType) => {
    const docs = ungradedDocuments.value.filter((document) => {
      // A lot of legacy documents don't have a submission type and we should consider those to be final drafts.
      if (documentSubmissionType == DocumentSubmissionType.finalDraft) {
        return (
          document.documentSubmissionType == undefined ||
          document.documentSubmissionType == documentSubmissionType
        );
      }

      return document.documentSubmissionType == documentSubmissionType;
    });

    return uniqueDocuments.value(docs);
  };
});

const gradedDocumentsByType = computed(() => {
  return (documentSubmissionType: DocumentSubmissionType) => {
    const docs = gradedDocuments.value.filter((document) => {
      // A lot of legacy documents don't have a submission type and we should consider those to be final drafts.
      if (documentSubmissionType == DocumentSubmissionType.finalDraft) {
        return (
          document.documentSubmissionType == undefined ||
          document.documentSubmissionType == documentSubmissionType
        );
      }

      return document.documentSubmissionType == documentSubmissionType;
    });

    return uniqueDocuments.value(docs);
  };
});

const uniqueDocuments = computed(() => {
  return (documents: SubmittedDocuments) => {
    const sortedDocuments = documents.sort(
      (a, b) =>
        (b.submittedAtTimestamp?.toMillis() ?? 0) -
        (a.submittedAtTimestamp?.toMillis() ?? 0)
    );

    const seenStudentIds = new Set<string>();

    const filteredDocuments = sortedDocuments.filter((document) => {
      if (document.studentId == undefined) return false;

      if (seenStudentIds.has(document.studentId)) {
        return false;
      }

      seenStudentIds.add(document.studentId);

      return true;
    });

    return filteredDocuments;
  };
});
</script>

<template>
  <VueFinalModal
    class="assignment-checklists-modal"
    content-class="assignment-checklists-modal-content w-full max-w-[800px] mx-auto h-[90%] mt-[2.5%] rounded overflow-hidden"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <NuxtLayout
      name="dialog"
      title="Assignment Summary"
      @close="$emit('close')"
    >
      <BaseLoading v-if="isLoading" />
      <div class="flex flex-col">
        <BaseTabBar
          v-if="tabs.length > 1"
          v-model="selectedTabKey"
          class="m-4"
          :tabs="tabs"
        />
        <ClassroomAssignmentSummary
          v-if="isLoading != true"
          :key="selectedTabKey"
          class="grow"
          :students="students"
          :assignment="assignment!"
          :ungraded-documents="ungradedDocumentsByType(selectedTabKey)"
          :graded-documents="gradedDocumentsByType(selectedTabKey)"
        />
      </div>
    </NuxtLayout>
  </VueFinalModal>
</template>

<style scoped>
.assignment-checklists-modal {
  z-index: 9999 !important;
}
.assignment-checklists-modal-content {
  display: flex;
  flex-direction: column;

  width: 600px;
  height: 90%;
  margin: auto;
  @apply rounded;
  margin-top: 64px;
}
</style>
